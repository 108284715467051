import * as React from "react"

import { cn } from "@/lib/utils"

const Input = React.forwardRef(({ className, type, isFocused, ...props }, ref) => {
    const input = ref ? ref : React.useRef()

    React.useEffect(() => {
        if (isFocused) {
            input.current.focus()
        }
    }, [])

    return (
        <input
            type={type}
            className={cn(
                "focus:border-brand-light flex h-10 w-full rounded-md border-2 border-stone-200 bg-white px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-stone-500 focus:outline-none focus:ring-0 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
                className,
            )}
            ref={input}
            {...props}
        />
    )
})
Input.displayName = "Input"

export { Input }
